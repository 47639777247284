<template>
  <v-container id="forgot-password">
    <div v-if="!successMsg">
      <v-row>
        <v-col cols></v-col>
        <v-col cols="10" sm="8" md="6" lg="5">
          <v-form
            @submit.prevent="submit"
            class="d-flex flex-column"
            novalidate
          >
            <h4 class="password-reset-title">
              {{ $t("passwordReset") }}
            </h4>

            <div class="password-reset-text mb-4">
              {{ $t("passwordResetTxt") }}
            </div>

            <v-text-field
              v-model="email"
              class="my-text-field"
              :placeholder="$t('registeredEmail')"
              type="email"
              outlined
              color="#262338"
              background-color="#FFF"
              :prepend-inner-icon="mail_icon"
              :error-messages="emailErrors"
            ></v-text-field>

            <v-btn
              color="#eaac30"
              type="submit"
              class="my-btn"
              height="56"
              depressed
              :loading="loader"
              >
                {{ $t("submit") }}
                <v-icon class="ml-4">mdi-chevron-right</v-icon>
              </v-btn
            >
          </v-form>
        </v-col>
        <v-col cols></v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="login-footer">
          {{$t('haveAccount')}}
          <span @click="showLoginForm" class="sign-up-link">
            {{$t('login')}}
          </span>
        </v-col>
      </v-row>
    </div>

    <div v-else>
      <v-row>
        <v-col cols class="d-none d-sm-block"></v-col>
        <v-col cols="12" sm="7" md="5" lg="4" class="d-flex justify-center">
          <div>
            <v-img
              contain
              height="40"
              width="40"
              src="@/assets/check.svg"
            ></v-img>
          </div>
        </v-col>
        <v-col cols class="d-none d-sm-block"></v-col>
      </v-row>

      <v-row>
        <v-col cols class="d-none d-sm-block"></v-col>
        <v-col cols="12" sm="7" md="5" lg="4">
          <div class="password-reset-text">
            {{ $t("passwordResetTxt2") }}
          </div>
        </v-col>
        <v-col cols class="d-none d-sm-block"></v-col>
      </v-row>

      <v-row class="mt-6">
        <v-col></v-col>
        <v-col cols="4">
          <v-btn
            color="#eaac30"
            class="my-btn"
            height="56"
            depressed
            block
            @click="$router.push({path: 'login'})"
          >
            <v-icon class="ml-4"> mdi-chevron-left</v-icon>
            {{ $t("backHome") }}
          </v-btn>
        </v-col>
        <v-col></v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
  import { validationMixin } from "vuelidate";
  import { required, email } from "vuelidate/lib/validators";
  import { mapActions, mapState } from "vuex";
  import axios from "axios";

  const notFoundEmail = function () {
    return !this.emailNotFound;
  };

  export default {
    name: 'ForgotPassword',
    mixins: [validationMixin],
    data() {
      return {
        email: "",
        loader: false,
        successMsg: false,
        lock_icon: "$vuetify.icons.lock_icon",
        mail_icon: "$vuetify.icons.mail_icon",
        view_icon: "$vuetify.icons.view_icon"
      };
    },
    validations: {
       email: {
        required,
        email,
      }
    },
    computed: {
      emailErrors() {
        const errors = [];
        if (!this.$v.email.$dirty) return errors;
        if (!this.$v.email.required) errors.push("Please enter your email!");
        if (!this.$v.email.email) errors.push("The email address is in the wrong format!");
        return errors;
      }
    },
    methods: {
      ...mapActions(["forgotPassword"]),
      submit() {
        this.$v.$touch();
        if (this.$v.$invalid) return;

        this.loader = true;

        this.forgotPassword(this.email).then(() => {
          this.loader = false;
          this.successMsg = true;
        }).catch(() => {
          this.loader = false;
        });
      },
      showLoginForm() {
        this.$router.push({path: 'login'});
      }
    }
  };
</script>

<style type="text/css">
  #forgot-password .my-text-field {
    border-radius: 12px;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
  }

  #forgot-password .my-text-field .v-input__slot {
    padding: 0 20px;
  }

  #forgot-password .my-text-field.v-text-field--outlined fieldset {
    border: 2px solid #EAAC30;
  }

  #forgot-password .my-text-field .v-input__prepend-inner {
    padding-right: 15px;
  }

  .password-reset-title {
    text-align: center;
    color: #4E4B66;
  }

  .password-reset-text {
    text-align: center;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #4E4B66;
  }

  .login-footer {
    text-align: center;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #262338;
  }

  .sign-up-link {
    font-weight: 600;
  }

  .sign-up-link:hover {
    cursor: pointer;
  }
</style>
<template>
  <v-container id="signup-page">
    <v-row>
      <v-col sm class="d-none d-sm-block"></v-col>
      <v-col cols="12" sm="8" md="6" lg="5">
        <v-form
          @submit.prevent="submit"
          class="mt-4 d-flex flex-column"
          novalidate
        >
          <v-text-field
            v-model="firstName"
            class="my-text-field"
            :placeholder="$t('fname')"
            outlined
            color="#262338"
            background-color="#FFF"
            :prepend-inner-icon="profile_icon"
            :error-messages="firstNameErrors"
            autocomplete="firstname"
          ></v-text-field>

          <v-text-field
            v-model="lastName"
            class="my-text-field"
            :placeholder="$t('lname')"
            outlined
            color="#262338"
            background-color="#FFF"
            :prepend-inner-icon="profile_icon"
            :error-messages="lastNameErrors"
            autocomplete="lastname"
          ></v-text-field>

          <v-text-field
            v-model="email"
            class="my-text-field"
            :placeholder="$t('email')"
            type="email"
            outlined
            color="#262338"
            background-color="#FFF"
            :prepend-inner-icon="mail_icon"
            :error-messages="emailErrors"
          ></v-text-field>

          <v-text-field
            v-model="password"
            id="signup-password"
            class="my-text-field"
            type="password"
            :placeholder="$t('password')"
            outlined
            color="#262338"
            background-color="#FFF"
            :prepend-inner-icon="lock_icon"
            :append-icon="view_icon"
            :error-messages="passwordErrors"
            autocomplete="new-password"
            @click:append="toggleViewPassword('signup-password')"
          ></v-text-field>

          <v-text-field
            v-model="confirmPassword"
            id="signup-confirm-password"
            class="my-text-field"
            type="password"
            :placeholder="$t('confirmPassword')"
            outlined
            color="#262338"
            background-color="#FFF"
            :prepend-inner-icon="lock_icon"
            :append-icon="view_icon"
            :error-messages="confirmPasswordErrors"
            autocomplete="confirm-password"
            @click:append="toggleViewPassword('signup-confirm-password')"
          ></v-text-field>

          <v-checkbox
            v-model="terms"
            background-color="transparent"
            :off-icon="checkbox_off"
            :on-icon="inspection_on"
            :error-messages="termsErrors"
            class="terms-checkbox"
          >
            <div slot="label" class="checkbox-label"
              :class="{'invalid-terms': this.$v.terms.$invalid}"
            >
              {{$t("accept")}}
              <a @click="$router.push({path: 'terms_of_use'})">
                {{$t("terms")}}
              </a>
            </div>
          </v-checkbox>

          <v-btn
            color="#eaac30"
            type="submit"
            class="my-btn"
            height="56"
            depressed
            block
            :loading="loader"
          >
            {{ $t("register") }}
            <v-icon class="ml-4">mdi-chevron-right</v-icon>
          </v-btn>
        </v-form>
      </v-col>
      <v-col sm class="d-none d-sm-block"></v-col>
    </v-row>

    <v-row>
      <v-col cols="12" class="login-footer">
        {{$t('haveAccount')}}
        <span @click="showLoginForm" class="sign-up-link">
          {{$t('login')}}
        </span>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { validationMixin } from "vuelidate";
  import { required, email, sameAs, minLength } from "vuelidate/lib/validators";
  import { mapActions, mapState } from "vuex";
  import axios from "axios";

  const userExists = function () {
    return !this.emailExists;
  };

  export default {
    name: 'SignUp',
    mixins: [validationMixin],
    data() {
      return {
        emailExists: false,
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        loader: false,
        terms: false,
        lock_icon: "$vuetify.icons.lock_icon",
        mail_icon: "$vuetify.icons.mail_icon",
        view_icon: "$vuetify.icons.view_icon",
        profile_icon: "$vuetify.icons.profile_icon",
        checkbox_off: "$vuetify.icons.checkbox_off",
        inspection_on: "$vuetify.icons.inspection_on"
      };
    },
    validations: {
      firstName: { required },
      lastName: { required },
      email: { required, email, userExists },
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
      terms: {
        sameAs: sameAs(() => true),
      }
    },
    computed: {
      firstNameErrors() {
        const errors = [];
        if (!this.$v.firstName.$dirty) return errors;
        if (!this.$v.firstName.required) errors.push(this.$t("profileErr1"));
        return errors;
      },
      lastNameErrors() {
        const errors = [];
        if (!this.$v.lastName.$dirty) return errors;
        if (!this.$v.lastName.required) errors.push(this.$t("profileErr2"));
        return errors;
      },
      emailErrors() {
        const errors = [];
        if (!this.$v.email.$dirty) return errors;
        if (!this.$v.email.required) errors.push(this.$t("logInErr1"));
        if (!this.$v.email.email) errors.push(this.$t("signUpErr1"));
        if (!this.$v.email.userExists) errors.push(this.$t("emailExists"));
        return errors;
      },
      passwordErrors() {
        const errors = [];
        if (!this.$v.password.$dirty) return errors;
        if (!this.$v.password.required) errors.push(this.$t("profileErr2"));
        if (!this.$v.password.minLength) errors.push(this.$t("accountErr3"));
        return errors;
      },
      confirmPasswordErrors() {
        const errors = [];
        if (!this.$v.confirmPassword.$dirty) return errors;
        if (!this.$v.confirmPassword.required) errors.push(this.$t("accountErr4"));
        if (!this.$v.confirmPassword.sameAsPassword) errors.push(this.$t("accountErr5"));
        return errors;
      },
      termsErrors() {
        const errors = [];
        if (!this.$v.terms.$dirty) return errors;
        if (!this.$v.terms.sameAs) errors.push(this.$t("profileErr3"));
        return errors;
      }
    },
    methods: {
      ...mapActions(["register"]),
      submit() {
        this.$v.$touch();
        if (this.$v.$invalid) return;

        this.loader = true;
        this.emailExists = false;

        this.register({
          firstname: this.firstName,
          lastname: this.lastName,
          email: this.email,
          password: this.password,
          confirm: this.confirmPassword,
        }).then(() => {
            this.loader = false;
            this.$gtag.event(
              'New sign up',
              {
                'event_category': 'SignUp'
              }
            );
            this.$router.push({
              name: "login",
              params: { new: "new" },
            });
          }).catch((error) => {
            if (error.response.data.result == "email already exists") {
              this.emailExists = true;
            }
            this.loader = false;
          });
      },
      toggleViewPassword(el) {
        let input = document.getElementById(el);

        if (input.type == 'password') {
          input.type = 'text';
        } else {
          input.type = 'password';
        }
      },
      showLoginForm() {
        this.$router.push({path: 'login'});
      }
    }
  };
</script>

<style type="text/css">
  #signup-page .my-text-field {
    border-radius: 12px;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
  }

  #signup-page .my-text-field .v-input__slot {
    /*border: 2px solid #EAAC30;*/
    padding: 0 20px;
  }

  #signup-page .my-text-field.v-text-field--outlined fieldset {
    border: 2px solid #EAAC30;
  }

  #signup-page .my-text-field .v-input__prepend-inner {
    padding-right: 15px;
  }

  #signup-page .terms-checkbox {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .forgot-password {
    margin-top: 20px;
    text-align: right;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #262338;
  }

  div.forgot-password:hover {
    cursor: pointer;
  }

  .login-footer {
    text-align: center;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #262338;
  }

  .sign-up-link {
    font-weight: 600;
  }

  .sign-up-link:hover {
    cursor: pointer;
  }

  .checkbox-label {
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #262338;
  }

  .checkbox-label a {
    font-weight: 600;
    color: #262338;
  }

  .invalid-terms,
  .checkbox-label.invalid-terms a {
    color: #FD6150;
  }
</style>
<template>
  <div class="pa-5" id="auth-wrapper">
    <v-row>
      <v-col cols="2" offset="10" sm="1" offset-sm="11">
        <v-menu offset-y min-width="118" content-class="language-menu">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="mr-2" v-on="on">
              <img src="@/assets/Language.svg">
            </v-btn>
          </template>
          <v-list>
            <v-list-item link v-for="(item,index) in languages" :key="index"
              @click="changeLanguage(item)">
              <v-list-item-title
                :class="[item.active ? 'active-list-item' : 'inactive-list-item']"
              >
                {{item.title}}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols></v-col>
      <v-col cols="10" sm="8" md="6" lg="5">
        <v-img
          contain
          height="168"
          src="@/assets/beenotes-logo-large.svg"
        ></v-img>
      </v-col>
      <v-col cols></v-col>
    </v-row>

    <component :is="currentComponent" />
  </div>
</template>

<script>
  import Login from "@/components/Auth/Login";
  import SignUp from "@/components/Auth/SignUp";
  import ForgotPassword from "@/components/Auth/ForgotPassword";
  import EmailConfirmed from "@/components/Auth/EmailConfirmed";
  import ResetPassword from "@/components/Auth/ResetPassword";

  export default {
    name: 'AuthWrapper',
    components: {
      Login,
      SignUp,
      ForgotPassword,
      EmailConfirmed,
      ResetPassword
    },
    props: ["currentComponent"],
    data() {
      return {
        languages: [
          {title: 'English', lang: 'en', active: false},
          {title: 'Ελληνικά', lang: 'el', active: false},
          {title: 'Español', lang: 'es', active: false}
        ]
      };
    },
    created() {
      for (let k = 0; k < this.languages.length; k++) {
        let item = this.languages[k];
        if (item.lang == this.$i18n.locale) {
          item.active = true;
        }
      }
    },
    methods: {
      changeLanguage(item) {
        for (let k = 0; k < this.languages.length; k++) {
          this.languages[k].active = false;
        }
        item.active = true;

        this.$i18n.locale = item.lang;
        if (item.lang != this.$route.params.lang) {
          var pageName = this.$route.path.split("/").pop();
          this.$router.push(`/${item.lang}/${pageName}`);
        }
      }
    }
  };
</script>

<style lang="scss">
  #auth-wrapper {
    margin-top: -70px;
  }

  @media screen and (max-width: 600px) {
    #auth-wrapper {
      margin-top: -128px;
    }
  }
</style>

<template>
  <v-container>
    <v-row>
      <v-col cols class="d-none d-sm-block"></v-col>
      <v-col cols="12" sm="7" md="5" lg="4" class="d-flex justify-center">
        <div class="pr-2">
          <v-img
            contain
            height="24"
            width="24"
            src="@/assets/check.svg"
          ></v-img>
        </div>
        <div class="email-confirmed-text">
          {{ $t("emailConfirmed") }}
        </div>
      </v-col>
      <v-col cols class="d-none d-sm-block"></v-col>
    </v-row>
    
    <v-row>
      <v-col cols class="d-none d-sm-block"></v-col>
      <v-col cols="12" sm="7" md="5" lg="4">
        <v-btn
          color="#eaac30"
          class="my-btn"
          height="56"
          depressed
          block
          @click="$router.push({path: 'login'})"
        >
          {{ $t("login") }}
          <v-icon class="ml-4">mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
      <v-col cols class="d-none d-sm-block"></v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'EmailConfirmed'
};
</script>

<style lang="css">
  .email-confirmed-text {
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #4E4B66;
  }
</style>

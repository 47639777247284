<template>
  <v-container id="reset-password">
    <v-row v-if="!successMsg">
      <v-col sm class="d-none d-sm-block"></v-col>
      <v-col cols="12" sm="8" md="6" lg="5">
        <form
          @submit.prevent="submit"
          class="mt-6 d-flex flex-column"
          novalidate
          autocomplete="off"
        >
          <h4 class="reset-password-title">{{ $t("passwordReset") }}</h4>
          <div class="reset-password-subtitle">
            {{ $t("newPassTxt") }}
          </div>

          <v-text-field
            id="reset-new-password"
            v-model="password"
            class="my-text-field"
            type="password"
            :placeholder="$t('password')"
            outlined
            color="#262338"
            background-color="#FFF"
            :prepend-inner-icon="lock_icon"
            :append-icon="view_icon"
            :error-messages="passwordErrors"
            @click:append="toggleViewPassword('reset-new-password')"
          ></v-text-field>

          <v-text-field
            id="reset-new-password-confirm"
            v-model="passwordConfirm"
            class="my-text-field"
            type="password"
            :placeholder="$t('confirmPassword')"
            outlined
            color="#262338"
            background-color="#FFF"
            :prepend-inner-icon="lock_icon"
            :append-icon="view_icon"
            :error-messages="passwordConfirmErrors"
            @click:append="toggleViewPassword('reset-new-password-confirm')"
          ></v-text-field>

          <div class="d-flex align-center justify-space-between">
            <v-btn
              color="#eaac30"
              class="my-btn"
              height="56"
              depressed
              @click="goToLogin"
            >
              <v-icon class="ml-4"> mdi-chevron-left</v-icon>
              {{ $t("backHome") }}
            </v-btn>

            <v-btn
              color="#eaac30"
              type="submit"
              class="my-btn"
              height="56"
              depressed
              :loading="loader"
            >
              {{ $t("submit") }}
              <v-icon class="ml-4">mdi-chevron-right</v-icon>
            </v-btn>
          </div>
        </form>
      </v-col>
      <v-col sm class="d-none d-sm-block"></v-col>
    </v-row>

    <div v-else>
      <v-row>
        <v-col cols class="d-none d-sm-block"></v-col>
        <v-col cols="12" sm="7" md="5" lg="4" class="d-flex justify-center">
          <div class="pr-2">
            <v-img
              contain
              height="24"
              width="24"
              src="@/assets/check.svg"
            ></v-img>
          </div>
          <div class="email-confirmed-text">
            {{ $t("passwordResetTxt3") }}
          </div>
        </v-col>
        <v-col cols class="d-none d-sm-block"></v-col>
      </v-row>

      <v-row>
        <v-col cols class="d-none d-sm-block"></v-col>
        <v-col cols="12" sm="7" md="5" lg="4">
          <v-btn
            color="#eaac30"
            class="my-btn"
            height="56"
            depressed
            block
            @click="goToLogin"
          >
            <v-icon class="ml-4"> mdi-chevron-left</v-icon>
            {{ $t("backHome") }}
          </v-btn>
        </v-col>
        <v-col cols class="d-none d-sm-block"></v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, sameAs } from "vuelidate/lib/validators";
import { mapActions } from "vuex";

export default {
  name: 'ResetPassword',
  mixins: [validationMixin],
  data() {
    return {
      password: "",
      passwordConfirm: "",
      loader: false,
      successMsg: false,
      lock_icon: "$vuetify.icons.lock_icon",
      view_icon: "$vuetify.icons.view_icon"
    };
  },
  validations: {
    password: {
      required,
    },
    passwordConfirm: {
      sameAsPassword: sameAs("password"),
      required,
    },
  },
  computed: {
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      if (!this.$v.password.required) errors.push(this.$t("logInErr2"));
      return errors;
    },
    passwordConfirmErrors() {
      const errors = [];
      if (!this.$v.passwordConfirm.$dirty) return errors;
      if (!this.$v.passwordConfirm.required) errors.push(this.$t("resetPassErr1"));
      if (!this.$v.passwordConfirm.sameAsPassword) errors.push(this.$t("resetPassErr2"));
      return errors;
    },
  },
  methods: {
    ...mapActions(["resetPassword"]),
    submit() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.loader = true;
      let token = this.$route.params.rstToken;
      let email = this.$route.query.email;

      this.resetPassword({
        email: email,
        token: token,
        password: this.password,
        password_confirmation: this.passwordConfirm,
      }).then(() => {
        this.loader = false;
        this.successMsg = true;
        this.goToLogin();
      }).catch(() => {
        this.loader = false;
      });
    },
    toggleViewPassword(elId) {
      let input = document.getElementById(elId);

      if (input.type == 'password') {
        input.type = 'text';
      } else {
        input.type = 'password';
      }
    },
    goToLogin() {
      this.$router.push({ name: "login", params: { lang: this.$i18n.locale } });
    }
  },
};
</script>

<style lang="css">
  #reset-password .my-text-field {
    border-radius: 12px;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
  }

  #reset-password .my-text-field .v-input__slot {
    padding: 0 20px;
  }

  #reset-password .my-text-field.v-text-field--outlined fieldset {
    border: 2px solid #EAAC30;
  }

  #reset-password .my-text-field .v-input__prepend-inner {
    padding-right: 15px;
  }

  .reset-password-title {
    text-align: center;
    color: #4E4B66;
  }

  .reset-password-subtitle {
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #4E4B66;
    margin-bottom: 20px;
  }

  .email-confirmed-text {
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.75px;
    color: #4E4B66;
  }
</style>

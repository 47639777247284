<template>
  <v-container id="login-page">
    <div v-if="!termsAccepted && submitClick">
      <div class="accept-terms">
        {{ $t("acceptMsg") }}
      </div>
      <div class="d-flex justify-center">
        <v-checkbox
          v-model="terms"
          background-color="transparent"
          :off-icon="checkbox_off"
          :on-icon="inspection_on"
          :error-messages="termsErrors"
        >
          <div slot="label" class="checkbox-label"
            :class="{ 'invalid-terms': this.$v.terms.$dirty }">
            {{ $t("acceptTerms1") }}
            <a @click="$router.push({path: 'terms_of_use'})">
              {{ $t("acceptTerms2") }}
            </a>
            {{ $t("acceptTerms3") }}
            <a @click="$router.push({path: 'privacy_policy'})">
              {{ $t("acceptTerms4") }}
            </a>
          </div>
        </v-checkbox>
      </div>

      <v-row class="mt-6">
        <v-col></v-col>
        <v-col cols="4">
          <v-btn
            color="#eaac30"
            class="my-btn"
            height="56"
            depressed
            block
            :loading="loader"
            @click="accept"
          >
            {{ $t("continue") }}
            <v-icon class="ml-4"> mdi-chevron-right</v-icon>
          </v-btn>
        </v-col>
        <v-col></v-col>
      </v-row>
    </div>
    <div v-else-if="!emailVerified && submitClick">
      <div class="d-flex flex-column">
        <h4 class="email-verification">{{ $t("emailVerification") }}</h4>
        <div class="accept-terms">
          {{ $t("profileErr4") }}
        </div>
        <div class="accept-terms">
          {{ $t("profileErr5") }}
          <a
            color="#262338"
            plain
            text
            :disabled="disableResend"
            @click="resendEmail"
          >
            {{ $t("resend") }}
          </a>
        </div>

        <v-row class="mt-6">
          <v-col></v-col>
          <v-col cols="4">
            <v-btn
              color="#eaac30"
              class="my-btn"
              height="56"
              depressed
              block
              @click="submitClick = false"
            >
              <v-icon class="ml-4"> mdi-chevron-left</v-icon>
              {{ $t("backHome") }}
            </v-btn>
          </v-col>
          <v-col></v-col>
        </v-row>
      </div>
    </div>
    <div v-else>
      <v-row>
        <v-col sm class="d-none d-sm-block"></v-col>
        <v-col cols="12" sm="8" md="6" lg="5">
          <v-form
            @submit.prevent="submit"
            class="mt-6 d-flex flex-column"
            novalidate
          >

            <div v-if="$route.params.new == 'new'"
              class="thank-you-mesg"
            >
              {{ $t("registerThankYou") }}
            </div>

            <v-text-field
              v-model="email"
              class="my-text-field"
              :placeholder="$t('email')"
              type="email"
              outlined
              color="#262338"
              background-color="#FFF"
              :prepend-inner-icon="mail_icon"
              :error-messages="emailErrors"
            ></v-text-field>

            <v-text-field
              v-model="password"
              id="login-password"
              class="my-text-field"
              type="password"
              :placeholder="$t('password')"
              outlined
              color="#262338"
              background-color="#FFF"
              :prepend-inner-icon="lock_icon"
              :append-icon="view_icon"
              :error-messages="passwordErrors"
              autocomplete="new-password"
              @click:append="toggleViewPassword"
            ></v-text-field>

            <v-btn
              color="#eaac30"
              type="submit"
              class="my-btn"
              height="56"
              depressed
              :loading="loader"
            >
              {{ $t("enter") }}
              <v-icon class="ml-4">mdi-chevron-right</v-icon>
            </v-btn>
          </v-form>

          <div class="forgot-password" @click="$router.push({path: 'forgot_password'})">
            {{$t('forgotPassword')}}
          </div>
        </v-col>
        <v-col sm class="d-none d-sm-block"></v-col>
      </v-row>

      <v-row>
        <v-col cols="12" class="login-footer">
          {{$t('noAccount')}}
          <span @click="showSignUpForm" class="sign-up-link">
            {{$t('signUp')}}
          </span>
        </v-col>
      </v-row>
    </div>
    <v-overlay :value="showLoader" z-index=11>
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
  import { validationMixin } from "vuelidate";
  import { required, sameAs } from "vuelidate/lib/validators";
  import { mapGetters, mapActions } from "vuex";
  import axios from "axios";

  const notFoundEmail = function () {
    return !this.emailNotFound;
  };

  export default {
    name: 'Login',
    mixins: [validationMixin],
    data() {
      return {
        email: "",
        password: "",
        emailNotFound: false,
        showLoader: false,
        loader: false,
        terms: false,
        emailVerify: false,
        disableResend: false,
        submitClick: false,
        lock_icon: "$vuetify.icons.lock_icon",
        mail_icon: "$vuetify.icons.mail_icon",
        view_icon: "$vuetify.icons.view_icon",
        checkbox_off: "$vuetify.icons.checkbox_off",
        inspection_on: "$vuetify.icons.inspection_on",
        appHomeUrl: process.env.VUE_APP_HOME_URL
      };
    },
    validations: {
      email: { required, notFoundEmail },
      password: { required, notFoundEmail },
      terms: {
        sameAs: sameAs(() => true),
      },
      emailVerify: {
        sameAs: sameAs(() => true),
      },
    },
    computed: {
      ...mapGetters([
        "termsAccepted",
        "emailVerified",
        "isLoggedIn"
      ]),
      emailErrors() {
        const errors = [];
        if (!this.$v.email.$dirty) return errors;
        if (!this.$v.email.required) errors.push(this.$t("logInErr1"));
        if (!this.$v.email.notFoundEmail) errors.push(this.$t("logInErr3"));
        return errors;
      },
      passwordErrors() {
        const errors = [];
        if (!this.$v.password.$dirty) return errors;
        if (!this.$v.password.required) errors.push(this.$t("logInErr2"));
        if (!this.$v.email.notFoundEmail) errors.push(this.$t("logInErr3"));
        return errors;
      },
      termsErrors() {
        const errors = [];
        if (!this.$v.terms.$dirty) return errors;
        if (!this.$v.terms.required) errors.push(this.$t("profileErr3"));
        return errors;
      },
      emailVerifyErrors() {
        const errors = [];
        if (!this.$v.emailVerify.$dirty) return errors;
        if (!this.$v.emailVerify.required) errors.push(this.$t("profileErr4"));
        errors.push(this.$t("profileErr5"));
        return errors;
      }
    },
    mounted() {
      let queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      let token = urlParams.get('token');

      if (token != null && !this.isLoggedIn) {
        this.showLoader = true;
        setTimeout(() => {
          this.submitClick = true;
          this.showLoader = false;
        }, 2000);
      }
    },
    methods: {
      ...mapActions(["login", "acceptTerms", "logout"]),
      submit() {
        this.submitClick = false;
        this.emailNotFound = false;
        this.$v.$touch();
        if (this.$v.email.$invalid || this.$v.password.$invalid) return;

        this.loader = true;
        this.login({
          loginId: this.email,
          password: this.password,
        }).then(() => {
          this.$gtag.event(
            'Successfull login',
            {
              'event_category': 'Login'
            }
          );
          this.loader = false;
          if (this.$v.emailVerify.$invalid) {
            this.submitClick = true;
          }
          if (this.termsAccepted && this.emailVerified) {
            this.$router.go({ path: "/" });
          } else {
            this.submitClick = true;
          }
        }).catch(() => {
          this.loader = false;
          this.emailNotFound = true;
        });
      },
      toggleViewPassword() {
        let input = document.getElementById("login-password");

        if (input.type == 'password') {
          input.type = 'text';
        } else {
          input.type = 'password';
        }
      },
      showSignUpForm() {
        this.$router.push({path: 'sign_up'});
      },
      accept() {
        this.$v.$touch();
        if (this.$v.terms.$invalid) return;
        this.acceptTerms()
          .then(() => {
            this.$router.push("/apiaries");
          })
          .catch(() => {
            return;
          });
      },
      resendEmail() {
        axios
          .get(`${process.env.VUE_APP_BASE_URL}/api/resend-email-verification`)
          .then((res) => {
            this.disableResend = true;
          })
          .catch((er) => {
            this.logout();
          });
      }
    }
  };
</script>

<style type="text/css">
  .login-form {
    width: 360px;
  }

  #login-page .my-text-field {
    border-radius: 12px;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
  }

  #login-page .my-text-field .v-input__slot {
    padding: 0 20px;
  }

  #login-page .my-text-field.v-text-field--outlined fieldset {
    border: 2px solid #EAAC30;
  }

  #login-page .my-text-field .v-input__prepend-inner {
    padding-right: 15px;
  }

  .forgot-password {
    margin-top: 20px;
    text-align: right;
    font-weight: 600;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #262338;
  }

  div.forgot-password:hover {
    cursor: pointer;
  }

  .email-verification {
    text-align: center;
    color: #4E4B66;
    margin-bottom: 10px;
  }

  .accept-terms {
    text-align: center;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #4E4B66;
  }

  .accept-terms a {
    text-decoration: none;
    color: #4E4B66 !important;
    font-weight: 600;
  }

  .thank-you-mesg {
    margin-bottom: 10px;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    letter-spacing: 0.25px;
    color: #4E4B66;
  }

  .login-footer {
    text-align: center;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.25px;
    color: #262338;
  }

  .sign-up-link {
    font-weight: 600;
  }

  .sign-up-link:hover {
    cursor: pointer;
  }

  .invalid-terms,
  .checkbox-label.invalid-terms a {
    color: #FD6150;
  }

  #login-page .plan-button {
    text-align: center;
    text-transform: none;
    background: #eaac30;
    color: #262338;
    padding: 12px 24px;
    border-radius: 12px;
    font-weight: 600;
  }
</style>